 
export default function Footer() {
  
    return (

        <>
        
        <footer className="bg-primary p-4 mt-auto">

            <h3 className="text-center text-white">© Ismail Kassim 2025 - All Rights Reserved.</h3>
 
        </footer>
        </>
    )

}